import Cloud from './Cloud'
export default {
    components:{ Cloud },
    data() {
        return {
            isReady: false,
            data: [],
            finishedIndex: 0,
            todayLessonIndex: -1,
            startBy:0,
            isDisplay: false,
            displayDate: null,
            finishDate: null,
            popupEventData: null,
            bannerIndex:0,
            bannerData:[],
            bannerInterval:null,
            bannerTimer:5000
        }
    },
    mounted() {
        this.$root.updateNotification()
        const params = new FormData()
        params.append('token', this.Auth.getToken())

        this.$axios
            .post(process.env.VUE_APP_API + 'banner' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                if (r.success === true) {
                    this.bannerData = r.data
                    this.bannerInit()
                }
            })

        this.$axios
            .post(process.env.VUE_APP_API + 'course' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                console.log(r)
                if (r.success === true) {
                    this.data = r.data

                    let today = new Date()
                    today.setHours(0,0,0,0)

                    // Display Date
                        if (r.display_date) {
                            this.displayDate = r.display_date
                            let d = new Date(r.display_date)
                            d.setHours(0,0,0,0)
                            if (today >= d) {
                                this.isDisplay = true
                            }
                        }

                    // Start By
                        if (r.start_by) {
                            this.startBy = parseInt(r.start_by) - 1
                        }
                    
                    // Finish Date
                        if (r.finish_date) {
                            this.finishDate = r.finish_date
                        }
                        
                    // Set Index
                        for (let i in this.data) {
                            if (this.data[i].date) {
                                if (this.data[i].date) {
                                    let date = new Date(this.data[i].date)
                                    date.setHours(0,0,0,0)
                                    if (date) {
                                        if (date <= today) {
                                            this.finishedIndex = i
                                        }
                                        if (date.toString() == today.toString()) {
                                            this.todayLessonIndex = i
                                        }
                                    }
                                }
                            }
                        }

                    this.isReady = true

                    this.loadPopupEvent()
                }
            })
    },
    methods: {
        loadPopupEvent () {
            const params = new FormData()
            params.append('token', this.Auth.getToken())
            this.$axios
                .post(process.env.VUE_APP_API + 'load-popup-event' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                .then(response => {
                    let r = response.data
                    if (r.success === true) {
                        this.popupEventData = r.data
                    }
                })
        },
        overPopup(e) {
            e.target.classList.add('over')
        },
        collapsePopup(e, index) {
            let el = this.$refs['item-'+index].$el
            if (!el.classList.contains('collapse')) {
                el.classList.add('collapse')
            } else {
                el.classList.remove('collapse')
            }
            e.preventDefault()
        },
        getStartDate () {
            if (this.data && this.data.length > 0) {
                let d = this.data[0].date
                if (d !== '' && d !== null && d !== undefined) {
                    let date = new Date(d)
                    return '我的課堂於 '+date.getFullYear() + ' 年 ' + (date.getMonth() + 1) + ' 月 ' + date.getDate() + ' 日開始'
                }
            }
        },
        getFinishDate () {
            if (this.finishDate !== '' && this.finishDate !== null) {
                let date = new Date(this.finishDate)
                return date.getFullYear() + ' 年 ' + (date.getMonth() + 1) + ' 月 ' + date.getDate() + ' 日'
            }
            return '待定'
        },
        getDisplayDate () {
            if (this.displayDate !== '' && this.displayDate !== null) {
                let date = new Date(this.displayDate)
                return date.getFullYear() + ' 年 ' + (date.getMonth() + 1) + ' 月 ' + date.getDate() + ' 日'
            }
        },
        getLessonStatus (index) {
            if (this.finishedIndex > index) {
                return this.$Lang.get('finish')
            } else if (index == this.todayLessonIndex) {
                return this.$Lang.get('today-lesson')
            }

            return this.$Lang.get('next-lesson')
        },
        goLesson (index) {
            if (this.data[index]) {
                let d = this.data[index]
                if (d.date) {
                    let today = new Date()
                    today.setHours(0,0,0,0)
                    let date = new Date(d.date)
                    date.setHours(0,0,0,0)
                    if (date <= today) {
                        this.$router.push('/lesson/'+d.id+'/')
                    }
                }
            }
        },
        bannerInit() {
            this.bannerInterval = setInterval(this.next, this.bannerTimer)
        },
        next() {
            this.bannerIndex = (this.bannerIndex + 1 < this.bannerData.length) ? this.bannerIndex + 1 : 0
            this.swapBanner()
        },
        swapBanner() {
            if (this.bannerData.length > 0) {
                clearInterval(this.bannerInterval)
                let c = this.$refs['image-container']
                c.scrollLeft = 480 * this.bannerIndex
                this.bannerInit()
            }
        },
        scroll(e) {
            clearInterval(this.bannerInterval)
            let target = e.target
            let index = target.getAttribute('index')
            this.bannerIndex = index
            this.swapBanner()
        }
    }
}