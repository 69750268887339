<template>
    <div class="page" v-if="isReady">
        <Cloud />
        <h1>小一面試班</h1>
        <div class="start-date">{{ getStartDate() }}</div>

        <div class="banner" v-if="bannerData.length > 0 && bannerData !== null">
            <ul class="image-container" ref="image-container">
                <li v-for="(data,index) in bannerData" :key="index">
                    <router-link :to="(data.type == 'event') ? '/interview-detail/'+data.id+'/' : '/other-detail/'+data.id+'/'"><img :src="data.cover" /></router-link>
                </li>
            </ul>
            <ul class="navigation">
                <li :class="[(bannerIndex == index) ? 'active' : '']" v-for="(data,index) in bannerData" :key="index" :index="index" @click="scroll" ></li>
            </ul>
        </div>

        <div class="course-wrapper" v-if="isDisplay">
            <div class="start">
                <div class="name">開始</div>
            </div>
            <div class="course-container">
                <template v-for="(course, i) in data" :key="i">
                    <div class="course-list">
                        <div class="arc" :style="[(i <= finishedIndex) ? 'background-color:hsl(' + (360 * i / data.length) + ',75%, 50%)' : '']"></div>
                        <div class="circle" @click="goLesson(i)" :style="[(i <= finishedIndex) ? 'cursor:pointer; background-color:hsl(' + (360 * i / data.length) + ',80%, 50%)' : '']">
                            <div class="number" :style="[(i <= finishedIndex) ? 'color:hsl(' + (360 * i / data.length) + ',70%, 50%)': '']">
                                {{ i + 1 }}
                            </div>
                            <div class="text" v-if="i <= todayLessonIndex">{{ getLessonStatus(i) }}</div>
                            <!--
                            <div v-if="i <= finishedIndex" class="star-container">
                                <div class="star left"></div>
                                <div class="star middle"></div>
                                <div class="star right"></div>
                            </div>
                            -->
                        </div>
                        <div class="lesson-detail-wrapper">
                            <div class="lesson-detail-fix">
                                <div class="lesson-detail" >
                                    <div>
                                        <!--<div class="lesson">第{{ (i+1).toLocaleString('zh-u-nu-hanidec') }}課 </div>-->
                                        <div class="lesson">{{ course.name }}</div>
                                        <div class="lesson-name" :title="course.materials_name">{{ course.materials_name }}</div>
                                        <div class="arrow" v-if="i == todayLessonIndex"></div>
                                    </div>
                                </div>
                                <!--
                                <div class="lesson-detail">
                                    <div>
                                        <div class="lesson">第{{ (i+1).toLocaleString('zh-u-nu-hanidec') }}課 </div>
                                        <div class="lesson">{{ course.name }}</div>
                                        <div class="lesson-name">下一課</div>
                                    </div>
                                </div>
                                -->
                            </div>
                        </div>
                        
                        <div :class="['end', ( (data.length) % 2  == 1) ? 'odd' : 'even']" v-if="i == data.length-1">
                            <div class="finish">
                                <div class="name">完成</div>
                            </div>
                            <div class="text">
                                預計完成日期<br>
                                {{ getFinishDate() }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="course-not-ready" v-if="!isDisplay">
            課堂資料準備中，
            <span v-if="displayDate !== null">將於 {{ getDisplayDate() }} 開始。</span>
            <span v-else>開學時間將稍後公佈。</span>
        </div>

        <div class="popup-event-container">
            <router-link class="collapse" :to="item.link"  v-for="(item, index) in popupEventData" :key="index" @mouseover="overPopup($event)" :ref="'item-'+index">
                <div class="section" v-if="item.type == 'event'" @click="collapsePopup($event, index)"><span>新<br>活<br>動</span></div>
                <div class="section" v-if="item.type == 'other'" @click="collapsePopup($event, index)"><span>新<br>資<br>訊</span></div>
                <div class="info">
                    <div v-if="item.photo && item.photo.file !== ''" class="photo" :style="'background-image:url('+item.photo.file+')'"></div>
                    <div class="title">
                        <span>{{ item.tc_title }}</span>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script src="./Course.js"></script>

<style scoped lang="scss">
@import 'Page.scss';
@keyframes FadePopup {
    0% {
        opacity:0;
        transform:translatex(100%);
    }
    100% {
        opacity:1;
        transform:translatex(30%);
    }
}

.banner {
    max-width:480px;
    margin:auto;
    margin-top:20px;
    margin-bottom:20px;
    overflow:hidden;
    .image-container {
        background:#ffffff;
        margin:0px;
        padding:0px;
        width:480px;
        height:156px;
        list-style:none;
        white-space:nowrap;
        position:relative;
        scroll-behavior:smooth;
        overflow:hidden;
        li {
            display:inline-block;
            width:480px;
            height:156px;
            overflow:hidden;
            a {
                display:block;
                padding:0px;
                margin:0px;
                img {
                    margin:auto;
                    display:block;
                    width:480px;
                    height:156px;
                }
            }
        }
    }
    .navigation {
        margin:10px 0px;
        padding:0px;
        list-style:none;
        position:relative;
        text-align:right;
        li {
            width:16px; height:16px;
            border-radius:8px;
            background:#ffffff;
            display:inline-block;
            margin-left:10px;
            cursor:pointer;
            &.active {
                background-color:#156b8a;
            }
            &:hover {
                background-color:#156b8a;
            }
        }
    }
}

.course-not-ready {
    margin:auto;
    max-width:500px;
    text-align:center;
    font-size:20px;
    padding-top:40px;
    padding-bottom:40px;
    span {
        font-size:20px;
    }
}

.popup-event-container {
    position:fixed;
    top:180px;
    right:0px;
    width:200px;
    z-index:1;

    a {
        box-shadow:0px 0px 10px rgba(0,0,0,0.2);
        border-radius:5px 0px 0px 5px;
        background:#ffffff;
        overflow:hidden;
        box-sizing:border-box;
        margin-bottom:10px;

        display:block;
        text-shadow:none;
        padding:0px 20px 0px 24px;
        box-sizing:border-box;
        position:relative;
        text-decoration:none;
        min-height:50px;
        transform:translateX(50%);
        animation:FadePopup 0.7s ease-in-out;
        animation-fill-mode:both;
        &.over {
            animation:all 0.7s ease-in-out;
        }


        &:after {
            position:absolute;
            top:0px;
            right:0px;
            width:70%;
            height:100%;
            background:linear-gradient(90deg, rgba(255,255,255,0), #ffffff 28%);
            
            content:'';
        }
        
        &:nth-child(1){
            animation-delay:1s;
        }
        &:nth-child(2){
            animation-delay:1.2s;
        }
        &:nth-child(3){
            animation-delay:1.4s;
        }
        &:hover {
            transform:translateX(0);
            &:after {
                display:none;
            }
        }

        .section {
            position:absolute;
            top:0px;
            left:0px;
            width:25px;
            height:100%;
            background:rgb(16, 83, 128);
            color:#ffffff;
            text-align:center;
            padding:5px;
            box-sizing:border-box;
            display:flex;
            align-items:center;
            justify-content:center;
            z-index:1;
            overflow:hidden;
            span {
                font-size:12px;
                line-height:14px;
                font-weight:bold;
            }
        }



        .info {
            display:table;
            width:100%;
            min-height:50px;
            .photo {
                display:table-cell;
                width:50px;
                background-size:cover;
                background-repeat:no-repeat;
            }
            .title {
                display:table-cell;
                vertical-align:middle;
                padding-left:10px;
                span {
                    color:#333;
                    text-overflow:ellipsis;
                    word-break:break-all;
                    max-height:40px;
                    overflow:hidden;
                    display:-webkit-box;
                    -webkit-line-clamp:2;
                    -webkit-box-orient:vertical;
                }
            }
        }

    }
}


.start-date {
    text-align:center;
    transform:scaleY(1.3);
    letter-spacing:1px;
}
.course-wrapper {
    position:relative;
    margin-top:80px;
    margin-bottom:160px;
    left:50%;

    .start {
        position:absolute;
        top:-40px;
        left:-60px;
        z-index:2;
        width:120px;
        height:120px;
        box-sizing:border-box;
        border-radius:60px;
        border:4px solid #ffffff;
        background:#f63f3f;
        display:flex;
        text-align:center;
        align-items:center;
        justify-content:center;
        box-shadow:0px 6px 0px rgba(0,0,0,0.2);
        .name {
            color:#f63f3f;
            font-size:40px;
            line-height:40px;
            text-shadow:stroke(2px, #ffffff);
        }
    }

    .course-container {
        position:relative;
        display:block;

        .course-list {
            position:relative;
            height:183px;
            z-index:1;
            width:240px;
            &:nth-child(odd) {
                .arc {
                    left:-21px;
                    mask-image:url('~@/assets/arc-right.png');
                }
                .circle {
                    left:110px;
                }
                .lesson-detail-wrapper {
                    position:absolute;
                    top:50%;
                    left:240px;
                    width:100%;
                }
                .lesson-detail {
                    left:0;
                    width:auto;
                    .arrow {
                        left:calc(100% + 10px);
                        transform:rotate(180deg);
                        
                    }
                }
            }
            &:nth-child(even) {
                .arc {
                    left:-202px;
                    mask-image:url('~@/assets/arc-left.png');
                }
                .circle {
                    left:-240px;
                }
                .lesson-detail-wrapper {
                    position:absolute;
                    top:50%;
                    left:0;
                }
                .lesson-detail {
                    text-align:right;
                    left:inherit;
                    right:250px;
                    width:max-content;
                    .arrow {
                        left:-110px;
                    }
                }
            }

            .arc {
                position:relative;
                top:0px;
                mask:no-repeat center center;
                mask-size:contain;
                width:212px;
                height:254px;
                background-color:#757575;
            }

            .circle {
                position:absolute;
                top:calc(50% - 25px);
                left:0px;
                width:120px;
                height:120px;
                box-sizing:border-box;
                border-radius:60px;
                border:4px solid #ffffff;
                background:#757575;
                display:flex;
                text-align:center;
                align-items:center;
                justify-content:center;
                flex-flow:column;
                box-shadow:0px 6px 0px rgba(0,0,0,0.2);
                .number {
                    font-family:"微軟正黑體", "Times";
                    display:block;
                    color:#757575;
                    font-size:55px;
                    line-height:55px;
                    padding-bottom:3px;
                    text-shadow:stroke(2px, #ffffff);
                    .done {
                        font-size:18px;
                        line-height:22px;
                        text-shadow:stroke(1px, #ffffff);
                    }
                }
                .text {
                    display:flex;
                    color:#ffffff;
                    font-size:20px;
                    line-height:20px;
                }
                .star-container {
                    position:absolute;
                    top:0px;
                    left:50%;
                    .star {
                        display:none;
                        background:url('~@/assets/star.png') no-repeat center center;
                        background-size:contain;
                        width:30px;
                        height:30px;
                        content:'';
                        position:absolute;
                        &.middle {
                            top:-18px;
                            left:-15px;
                        }
                        &.left {
                            top:-13px;
                            left:-43px;
                            transform:rotate(-10deg);
                        }
                        &.right {
                            top:-13px;
                            left:13px;
                            transform:rotate(20deg);
                        }
                    }
                }
            }

            .lesson-detail {
                position:absolute;
                left:0px;
                height:120px;
                display:table;
                > div { 
                    display:table-cell;
                    vertical-align:middle;
                }
                top:calc(50% - 25px);
                .lesson {
                    font-size:16px;
                    line-height:22px;
                }
                .lesson-name {
                    font-size:20px;
                    line-height:24px;
                    transform:scaleY(1.1);
                    max-height:72px;
                    max-width:200px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-line-clamp:3;
                    -webkit-box-orient:vertical;
                }
                .arrow {
                    background:url('~@/assets/big-arrow.png') no-repeat center center;
                    background-size:contain;
                    width:100px;
                    height:128px;
                    position:absolute;
                    top:0px;
                    left:0;
                }
            }
        }
    }

    .end {
        position:absolute;
        top:160px;
        left:-50px;
        z-index:2;

        &.odd {
            .text {
                left:-170px;
                text-align:right;
            }
        }

        &.even {
            .text {
                left:150px;
            }
        }
        .finish {
            width:120px;
            height:120px;
            box-sizing:border-box;
            border-radius:60px;
            border:4px solid #ffffff;
            background:#757575;
            display:flex;
            text-align:center;
            align-items:center;
            justify-content:center;
            flex-flow:column;
            box-shadow:0px 6px 0px rgba(0,0,0,0.2);
            .name {
                position:absolute;
                color:#757575;
                font-size:40px;
                line-height:40px;
                text-shadow:stroke(2px, #ffffff);
            }
        }
        .text {
            position:absolute;
            top:35px;
            left:150px;
            font-size:18px;
            transform:scaleY(1.2);
            white-space:nowrap;
        }
    }
}


@media only screen and (max-width:700px) {
    .banner {
        transform:scale(0.8);
    }
    .course-wrapper {

        .start {
            top:-40px;
            left:-30px;
            width:80px;
            height:80px;
            border-radius:40px;
            border:3px solid #ffffff;
            .name {
                font-size:17px;
                text-shadow:stroke(1px, #ffffff);
            }
        }

        .course-container {
            .course-list {
                height:128px;

                .lesson-detail {
                    height:100px;
                    .lesson-name { font-size:14px; line-height:16px; }
                    .lesson { font-size:14px; line-height:20px; }
                    .arrow { width:40px; height:40px; }
                }
                &:nth-child(odd) {
                    .arc {
                        left:-30px;
                    }
                    .circle {
                        left:85px;
                    }
                    .lesson-detail {
                        left:inherit;
                        text-align:right;
                        width:max-content;
                        right:410px;
                        .arrow {
                            left:-50px;
                            top:calc(50% - 15px);
                            transform:rotate(0deg);
                        }
                    }
                }
                &:nth-child(even) {
                    .arc {
                        left:-162px;
                    }
                    .circle {
                        left:-175px;
                    }
                    .lesson-detail {
                        text-align:left;
                        left:-80px;
                        width:200px;
                        .arrow {
                            left:calc(100% + 10px);
                            top:calc(50% - 15px);
                            transform:rotate(180deg);
                        }
                    }
                }                
                .arc {
                    width:180px;
                    height:180px;
                }
                .circle {
                    top:calc(50% - 10px);
                    width:80px;
                    height:80px;
                    border-radius:40px;
                    border-width:3px;
                    .number {
                        font-size:30px;
                        line-height:30px;
                        text-shadow:stroke(1px, #ffffff);
                    }
                    .text {
                        font-size:15px;
                    }
                }
            }
        }
        .end {
            top:120px;

            &.odd {
                .text {
                    left:-130px;
                }
            }
            &.even {
                .text {
                    left:100px;
                }
            }
            .finish {
                width:80px;
                height:80px;
                border-radius:40px;
                .name {
                    font-size:20px;
                    line-height:30px;
                    text-shadow:stroke(1px, #ffffff);
                }
            }
            .text {
                top:20px;
                font-size:14px;
            }
        }
    }
    @keyframes FadePopup {
        0% {
            opacity:0;
            transform:translatex(100%);
        }
        100% {
            opacity:1;
            transform:translatex(0%);
        }
    }
    @keyframes collapse {
    }
    .popup-event-container {
        top:inherit;
        bottom:100px;
        a {
            animation:collapse 1s ease;
            animation-fill-mode:both;
            transform:translateX(0);
            &:after {
                display:none;
            }

            &.collapse {
                transform:translateX(75%);
            }
        }
    }
}
</style>